@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bodoni+Moda&family=Roboto+Slab:wght@300;400&display=swap);


/* @font-face {
    font-family: 'BodoniFLF Regular';
    font-style: normal;
    font-weight: normal;
    src: local('BodoniFLF Regular'), url('./fonts/BodoniFLF-Roman.woff') format('woff');
    } */


:root {

  --primary: #009b9e;

  --secondary: #424242;
  --error: #ff4a4a;
}

/* base styles & title */
body {

  font-family: 'Roboto Slab', 'serif';
  font-family: 'Bodoni Moda', 'serif';


  color: #424242;


  color: var(--secondary);
  background-color: #f4f4f4;
  /* background-color: #090131; */
  /* background-color: #B6Ad9E; */
}
.App {
  max-width: 980px;
  margin: 0 auto;
  /* background-color: #fafafa; */
  /* padding: 5px 10px; */
  /* border-radius: 10px; */
}

.title h1 {
  /* display: inline-block; */
  color: #009b9e;
  color: var(--primary);
  font-size: 1.2rem;
  font-size: 1.8rem;
  letter-spacing: 2px;
  font-weight: normal;
  padding-left: 2px;
}

.highlight {
  color: #009b9e;
  color: var(--primary);

}

.title h2,
.title h3,
.title p {
  text-align: center;
}

.title h2 {
  margin-top: 10vh;
  font-size: 2.6rem;
}

/* upload form styles */

form {
  margin: 30px auto 10px;
  text-align: center;
}

label input {
  height: 0;
  width: 0;
  opacity: 0;
}
label {
  display: block;
  width: 30px;
  height: 30px;
  border: 1px solid #009b9e;
  border: 1px solid var(--primary);
  border-radius: 50%;
  margin: 10px auto;
  /* margin: 2.5vh auto; */
  line-height: 30px;
  color: #009b9e;
  color: var(--primary);
  font-family: "Noto Serif";
  font-weight: bold;
  font-size: 24px;
}
label:hover {
  background: #009b9e;
  background: var(--primary);
  color: white;
}
.output {
  height: 30px;
  font-size: 0.5rem;
}
.error {
  color: #ff4a4a;
  color: var(--error);
}

/* progress bar styles */
.progress-bar {
  height: 5px;
  background: #009b9e;
  background: var(--primary);
  margin-top: 20px;
}

/* image grid styles */

.img-grid {
  margin: 20px auto;
  /* margin: 10px auto; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-gap: 40px; */
  grid-gap: 15px;
  /* grid-gap: 15px; */
  padding: 2px 0;
}
.img-wrap {
  overflow: hidden;
  height: 0;
  /* padding: 50% 0; */
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.9;
  border-radius: 5px;
}
.img-wrap img {
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  max-width: 100%;
  /* margin: auto auto; */
  position: absolute;

  top: 0;
  left: 0;
}

/* modal styles */

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

